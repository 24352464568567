import { createContext, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setConsultRequired, toggleInsurance } from '../../features/cart/cartSlice';
import posthog from 'posthog-js';

export const CheckoutContext = createContext(null);

export function CheckoutContextProvider({ children }) {
    const dispatch = useDispatch();

    //redux state selectors
    const { isConsultOrder, hstInCart, lowScoreConsultRequired, stateRequiredTelehealth } =
        useSelector((state) => state.cart);

    // Checkout step logic
    const steps = {
        //These steps are for insurance review before payment
        'check-coverage': { backButtonActive: false },
        'checking-eligibility': { backButtonActive: false },
        'insurance-card': { backButtonActive: true },
        'insurance-details': { backButtonActive: true },
        'insurance-card-question': { backButtonActive: true },
        'insurance-review': { backButtonActive: true },
        'is-eligible': { backButtonActive: false },
        'not-found': { backButtonActive: true },
        'not-eligible': { backButtonActive: false },
        'payment-choice': { backButtonActive: false },
        //The following steps occur after insurance review
        'address-page': { backButtonActive: false },
        'checkout-success': { backButtonActive: false },
        'consult-booking': { backButtonActive: false },
        'insurance-alternative': { backButtonActive: false },
        'insurance-alternative-check': { backButtonActive: false },
        'payment-information': { backButtonActive: false },
        'required-consult-booking': { backButtonActive: false },
        'submitting-payment': { backButtonActive: false },
    };
    const [checkoutStep, setCheckoutStep] = useState();

    function finishInsuranceCheck({ routeToRequiredBooking }) {
        if (routeToRequiredBooking) {
            setCheckoutStep('required-consult-booking');
        } else if (isConsultOrder) {
            setCheckoutStep('consult-booking');
        } else {
            setCheckoutStep('address-page');
        }
    }

    function skipInsurance() {
        dispatch(
            setConsultRequired((stateRequiredTelehealth && hstInCart) || lowScoreConsultRequired),
        );
        dispatch(toggleInsurance(false));
        finishInsuranceCheck({
            routeToRequiredBooking:
                (stateRequiredTelehealth && hstInCart) || lowScoreConsultRequired,
        });

        posthog?.capture('Continue without Insurance');
    }

    const contextValue = {
        steps,
        checkoutStep,
        finishInsuranceCheck,
        skipInsurance,
        setCheckoutStep,
    };

    return <CheckoutContext.Provider value={contextValue}>{children}</CheckoutContext.Provider>;
}

export function useCheckoutContext() {
    const context = useContext(CheckoutContext);
    if (!context) {
        throw new Error('Checkout Context must be used within CheckoutContextProvider');
    }
    return context;
}
