import posthog from 'posthog-js';
import { useDispatch } from 'react-redux';

import { setAssessmentStep } from '../../../features/assessment/assessmentSlice';

import ReactGA from 'react-ga4';

function LowScore() {
    const dispatch = useDispatch();

    return (
        <div className="mx-auto max-w-5xl text-center">
            <div className="pr-0 lg:pr-8">
                <h4 className="mb-4 font-heading text-3xl text-purple-fair lg:mb-6">
                    ASSESSMENT RESULTS
                </h4>
                <h2 className="mb-4 font-heading text-5xl font-bold text-white lg:mb-5 lg:text-8xl">
                    We recommend you meet with a GEM Clinician
                </h2>
                <p className="mb-4 text-lg sm:text-xl text-gray-light">
                    You don't show all the typical signs of Sleep Apnea, however, all patients are
                    different, would you like to meet with a GEM CLINICIAN to review in more detail
                    if you qualify for a Home <span className="underline">Sleep Apnea</span> Test?
                </p>
            </div>
            <button
                className="btn-primary"
                onClick={() => {
                    posthog?.capture('Answered a Question', {
                        question: 'Assessment Result',
                        answer: 'Low score continue',
                    });

                    ReactGA.event({
                        category: 'User',
                        action: 'Assessment Result',
                        label: 'Continue_Result',
                    });

                    ReactGA.event({
                        category: 'User',
                        action: 'Assessment Result',
                        label: 'Continue_NextSteps',
                    });

                    dispatch(setAssessmentStep('create-account'));

                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }}>
                CONTINUE TO CREATE AN ACCOUNT
            </button>
        </div>
    );
}

export default LowScore;
